import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import remarkBreaks from "remark-breaks";
import { Link } from "@/i18n/routing";
import { ReactNode } from "react";

interface MarkdownRenderProps {
  content: ReactNode;
}

export const MarkdownRender = ({ content }: MarkdownRenderProps) => {
  return (
    <Markdown
      remarkPlugins={[remarkBreaks, remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        h1: ({ ...props }) => <h1 {...props} />,
        h2: ({ ...props }) => <h2 {...props} />,
        h3: ({ ...props }) => (
          <h3
            className="text-xl font-bold tracking-tight text-[#080808] mt-6"
            {...props}
          />
        ),
        h4: ({ ...props }) => (
          <h4
            className="font-bold tracking-tight text-[#080808] mt-6"
            {...props}
          />
        ),
        h5: ({ ...props }) => <h5 {...props} />,
        h6: ({ ...props }) => <h6 {...props} />,
        a: ({ href = "#", ...props }) => (
          <Link href={href} className="underline" {...props} />
        ),
        ul: ({ ...props }) => (
          <ul className="list-disc list-inside ml-5" {...props} />
        ),
        li: ({ ...props }) => <li {...props} />,
        table: ({ ...props }) => (
          <div className="overflow-x-auto">
            <table className="w-full border border-gray-300 mt-4" {...props} />
          </div>
        ),
        thead: ({ ...props }) => (
          <thead
            className="bg-gray-200 text-left text-sm text-gray-600"
            {...props}
          />
        ),
        tbody: ({ ...props }) => (
          <tbody className="text-sm text-gray-700" {...props} />
        ),
        th: ({ ...props }) => (
          <th className="py-2 px-4 border-b border-gray-300" {...props} />
        ),
        td: ({ ...props }) => (
          <td className="py-2 px-4 border-b border-gray-300" {...props} />
        ),
      }}
    >
      {String(content)}
    </Markdown>
  );
};
