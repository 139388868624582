"use client";

import { updateCookies } from "@/lib/cookies";
import { useState } from "react";
import { Checkbox } from "@/components/ui/checkbox";
import { useTranslations } from "next-intl";
import { MarkdownRender } from "@/components/markdown";

export const CookieBanner = () => {
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [analyticsConsent, setAnalyticsConsent] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);

  const t = useTranslations("CookiesConsent");
  const b = useTranslations("Buttons");

  const handleAccept = async () => {
    setSettingsVisible(false);
    updateCookies("_necessaryCookie", "true", 0);
    updateCookies("_analyticsCookie", "true", 60 * 60 * 24 * 30 * 6); // 6 months
    updateCookies("_marketingCookie", "true", 60 * 60 * 24 * 365); // 1 year
  };

  const handleCustomAccept = async () => {
    setSettingsVisible(false);
    updateCookies("_necessaryCookie", "true", 0);
    updateCookies(
      "_analyticsCookie",
      `${analyticsConsent}`,
      60 * 60 * 24 * 30 * 6
    ); // 6 months
    updateCookies(
      "_marketingCookie",
      `${marketingConsent}`,
      60 * 60 * 24 * 365
    ); // 1 year
  };

  const handleReject = async () => {
    setSettingsVisible(false);
    updateCookies("_necessaryCookie", "true", 0);
    updateCookies("_analyticsCookie", "false", 60 * 60 * 24 * 30 * 6); // 6 months
    updateCookies("_marketingCookie", "false", 60 * 60 * 24 * 365); // 1 year
  };

  const toggleSettings = () => {
    setSettingsVisible(!settingsVisible);
  };

  return (
    <>
      <div className="relative">
        <div className="fixed bottom-0 md:bottom-10 md:right-10 max-w-[480px] flex flex-col gap-6 bg-black text-white px-[20px] py-[15px] shadow-lg md:rounded-md">
          <h3 className="font-bold text-xl">{t.rich("title")}</h3>
          {settingsVisible ? (
            <>
              <p className="text-sm m-[5px]">{t.rich("description")}</p>
              <div className="flex flex-col gap-6 m-[10px]">
                <div className="flex items-center space-x-2">
                  <Checkbox checked disabled id="essentials" />
                  <label
                    htmlFor="terms"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    {t.rich("essential")}
                  </label>
                </div>
                <div className="flex items-center space-x-2">
                  <Checkbox
                    checked={analyticsConsent}
                    onCheckedChange={(value) => {
                      if (value !== "indeterminate") {
                        setAnalyticsConsent(value);
                      }
                    }}
                    id="analytics"
                  />
                  <label
                    htmlFor="terms"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    {t.rich("analytics")}
                  </label>
                </div>
                <div className="flex items-center space-x-2">
                  <Checkbox
                    checked={marketingConsent}
                    onCheckedChange={(value) => {
                      if (value !== "indeterminate") {
                        setMarketingConsent(value);
                      }
                    }}
                    id="marketing"
                    className="checked:bg-black checked:text-white"
                  />
                  <label
                    htmlFor="terms"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    {t.rich("marketing")}
                  </label>
                </div>
              </div>
            </>
          ) : (
            <div className="text-sm">
              {<MarkdownRender content={t.rich("content")} />}
            </div>
          )}
          <div className="flex justify-end gap-3">
            <button onClick={toggleSettings} className="btn">
              {settingsVisible ? b.rich("go_back") : b.rich("cookie_settings")}
            </button>
            <button onClick={handleReject} className="btn btn-secondary">
              {b.rich("reject")}
            </button>
            <button
              onClick={settingsVisible ? handleCustomAccept : handleAccept}
              className="btn btn-primary"
            >
              {b.rich("accept")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
